<template>
  <b-col
    lg="3"
    md="3"
  >
    <b-form-group
      label="Neto"
      label-for="valorNeto"
      label-cols-md="4"
      class="text-right"
      style="margin-bottom: -5%;"
    >
      <p class="text-primary text-md-left text-sm-right mt-total ml-total">
        {{ formatoPeso(valorNeto) }}
      </p>
    </b-form-group>
    <b-form-group
      label="IVA"
      label-for="iva"
      label-cols-md="4"
      class="text-right"
      style="margin-bottom: -4%;"
    >
      <p class="text-primary text-md-left text-sm-right mt-total ml-total">
        {{ formatoPeso(valorIva) }}
      </p>
    </b-form-group>
    <b-form-group
      label="Total OC"
      label-for="valorTotal"
      label-cols-md="4"
      class="text-right h5"
      style="margin-bottom: -1%;"
    >
      <p class="h5 text-primary text-md-left text-sm-right mt-total ml-total">
        {{ formatoPeso(valorTotal) }}
      </p>
    </b-form-group>
  </b-col>
</template>

<script>
import {
  BFormGroup, BCol,
} from 'bootstrap-vue'
import { formatos } from '@/mixins/forms'
// import { computed } from '@vue/composition-api';

export default {
  components: {
    BFormGroup,
    BCol,
  },
  mixins: [formatos],
  computed: {
    valorTotal() {
      return Math.round(this.valorNeto * 1.19)
    },
    valorIva() {
      return this.valorTotal - this.valorNeto
    },
  },
  data() {
    return {
      iva: 0,
      Total: 0,
    }
  },
  props: {
    valorNeto: {
      type: Number,
      required: true,
    },
  },
  // watch: {
  //   valorNeto() {
  //     console.log(2)
  //     this.total = Math.round(this.valorNeto * 1.19)
  //     this.iva = this.total - this.valorNeto
  //   },
  // },
}
</script>

<style lang="scss">

  @media (max-width:616px){
    .mt-total {
      margin-top: 8px;
      margin-bottom: 10px;
    }
  }
  @media (min-width:615px){
    .mt-total {
      margin-top: 8px;
      margin-bottom: 18px;
    }
    .ml-total {
      margin-left: 3%;
    }
  }

</style>
